import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { fonts } from '../../styles/theme';
import Button from '../Button';
import { TextP } from '../Text';
import {
  PopoverWrapper, PopoverBodyWrapper, ButtonWrapper, IconWrapper, ArrowWrapper, ButtonCountStyled,
} from './styles';

type ExampleProps = {
  children: React.ReactNode
  buttonTheme?: string
  buttonFont?: string
  openOnHover?: boolean
  location?: 'bottom' | 'bottomCenter'
  buttonLabel: string
  useArrow?: boolean
  icon?: any
  flexDirection?: string
  buttonWidth?: string
  fitContent?: boolean
  buttonCount?: number
};

export const Popover = ({
  children, buttonTheme, openOnHover, location, buttonLabel, useArrow = false, icon, flexDirection = 'row', fitContent = false, buttonWidth, buttonFont = fonts.font1.H14B,
  buttonCount,
}: ExampleProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PopoverWrapper
      fitContent={fitContent}
      onMouseEnter={() => {
        if (openOnHover) {
          setIsOpen(true);
        }
      }}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Button
        id="modal-button"
        onClick={() => { setIsOpen(!isOpen); }}
        theme={buttonTheme}
        buttonWidth={buttonWidth || '100%'}
      >
        <ButtonWrapper flexDirection={flexDirection}>
          {icon && (
            <IconWrapper>
              <FontAwesomeIcon icon="bars" size="2x" />
            </IconWrapper>
          )}

          <TextP font={buttonFont}>
            {buttonLabel}
            {buttonCount && (
              <ButtonCountStyled>{buttonCount}</ButtonCountStyled>
            )}
            {useArrow && (
              <ArrowWrapper>
                <FontAwesomeIcon icon="angle-down" size="lg" />
              </ArrowWrapper>
            )}
          </TextP>

        </ButtonWrapper>
      </Button>
      <PopoverBodyWrapper
        isOpen={isOpen}
        location={location}
      >
        {children}
      </PopoverBodyWrapper>
    </PopoverWrapper>
  );
};
